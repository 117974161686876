/*eslint-disable */
// import ChatBotDataSrcModel from '../../Model/ChatBotDataSrc'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let ChatBotDataSrcs = {
  /**
   * chatbot_data_srcList
   */
  async chatbot_data_srcList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "chatbot_data_src_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at chatbot_data_srcList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * chatbot_data_srcView
   */
  async chatbot_data_srcView (context, chatbotDataSrcId) {
    try {      
      let post_data = new FormData();
      post_data.append('cbds_id', chatbotDataSrcId);
      return await request.curl(context, "chatbot_data_src_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at chatbot_data_srcView() and Exception:",err.message)
    }    
  },

  /**
   * chatbot_data_srcAdd
   */
  async chatbot_data_srcAdd (context, chatbot_data_srcObj) {
    try{
      let post_data = new FormData();
    
      for (let key in chatbot_data_srcObj) {
        post_data.append(key, chatbot_data_srcObj[key]);
      }

      return await request.curl(context, "chatbot_data_src_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at chatbot_data_srcAdd() and Exception:',err)
    }
  },

  /**
   * chatbot_data_srcEdit
   */
  async chatbot_data_srcEdit (context, chatbot_data_srcObj) {
    try{
      let post_data = new FormData();
    
      for (let key in chatbot_data_srcObj) {
        post_data.append(key, chatbot_data_srcObj[key]);
      }

      return await request.curl(context, "chatbot_data_src_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at chatbot_data_srcEdit() and Exception:',err.message)
    }
  },

  /**
   * chatbot_data_srcDelete
   */
  async chatbot_data_srcDelete (context, chatbotDataSrcId) {
    try{
      let post_data = new FormData();
      
      post_data.append('cbds_id', chatbotDataSrcId);

      return await request.curl(context, "chatbot_data_src_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at chatbot_data_srcDelete() and Exception:',err.message)
    }
  },
  /**
   * chatbot_data_trainBot
   */
  async chatbot_data_trainBot (context, chatbot_data_srcObj) {
    try{
      let post_data = new FormData();
      for (let key in chatbot_data_srcObj) {
        post_data.append(key, chatbot_data_srcObj[key]);
      }

      return await request.curl(context, "chatbot_data_src_train_bot", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at chatbot_data_srcDelete() and Exception:',err.message)
    }
  }
}

export {
  ChatBotDataSrcs
}
